@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    * {
        font-family: Sora, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
    }
    a:hover,
    a:active, 
    a:focus {
        @apply text-blue-light;
    }
    #content {
        animation: fadeInAnimation ease 1s; 
        animation-iteration-count: 1; 
        animation-fill-mode: forwards; 
    }
    @keyframes fadeInAnimation { 
        0% { 
            opacity: 0; 
        } 
        100% { 
            opacity: 1; 
        } 
    }
    input[type=text] {   
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}

@layer components {
    header {
        position: absolute;
        top: 0;
    }
    .site-logo {
        width: 102px;
    }
    .subline {
        padding-left: 10vw;
        padding-right: 10vw;
    }
    #email-submit {
        width: 1.5rem;
        height: 1.5rem;
        background-image: url(../media/rsaquo-black.svg);
        background-position: center center;
        background-repeat: no-repeat;
    }
    #mce-EMAIL {
        transition: background-color 0.5s ease,
                    color 0.5s ease;
    }
    #mce-EMAIL:hover::placeholder,
    #mce-EMAIL:focus {
        @apply text-white;
    }
    #mce-EMAIL:focus:hover::placeholder {
        @apply text-grey-1;
    }
    #mce-EMAIL:hover + #email-submit,
    #mce-EMAIL:focus + #email-submit {
        background-image: url(../media/rsaquo-white.svg);
    }
    .markdown {
        * + * {
            @apply mt-4;
        }
        h1 {
            @apply text-3xl font-bold leading-none my-16;
        }
        h2 {
            @apply text-xl font-bold leading-none mt-16 mb-8;
        }
        h3 {
            @apply text-lg font-bold leading-none my-6;
        }
        h4 {
            @apply font-bold my-4;
        }
        ul {
            @apply list-disc ml-6;
        }
        li {
            @apply my-2;
        }
    }
    footer {
        width: 100%;
        background: #000;
        position: relative;
        z-index: 1;
    }
    .footerContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-family: "Sora",sans-serif;
        color: #fff;
        max-width: 1440px;
        margin-left: auto!important;
        margin-right: auto!important;
        padding: 96.2px 5% 88px;
    }
    .sprite--room_logo_white {
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        background-repeat-x: no-repeat;
        background-repeat-y: no-repeat;
        background-size: contain;
        background: url(../media/logo_white.svg) 50% no-repeat;
        background-size: contain;
    }
    .footerItemContainer {
        padding-right: 30px;
        padding-bottom: 30px;
    }
    .footerRoomLogo {
        width: 80px;
        height: 51px;
    }
    .productSubTitle {
        font-weight: 300;
        font-size: 24px;
        line-height: 31.2px;
        max-width: 300px;
        margin-top: 34px;
    }
    .footerItemLeft {
        padding-right: 40px;
    }
    .footerItemRight {
        padding-left: 30px;
    }
    .footerLinkHeader {
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 1.2px;
        text-transform: uppercase;
    }
    .footerLinkHeader~div {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-top: 14px;
    }
    .footerLinks {
        color: #fff;
        font-family: "Sora",sans-serif!important;
        font-weight: 700;
        font-size: 16px;
        line-height: 20.16px;
        text-decoration: none;
    }
    .footerLinks:hover {
        color: #08f;
    }
    .footerLinks.active {
        color: #fff;
        pointer-events: none;
        cursor: default;
        opacity: .45;
    }
    .d-flex {
        display: flex!important;
    }

    .osano-cm-window {
        font-size: 14px;
    }

    .osano-cm-dialog {
        padding: 40px 80px;
    }

    .osano-cm-window button {
        border-radius: 100px;
        border: 2px solid #000;
    }

    button.osano-cm-save {
        background: #fff;
        color: #000;
    }

    button.osano-cm-save:hover,
    button.osano-cm-save:active,
    button.osano-cm-save:focus {
        color: #fff;
    }

    button.osano-cm-widget {
        display: none;
    }
}

@layer utilities {
}
